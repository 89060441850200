@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --font-poppins: 'Poppins', sans-serif;
  --font-family-default: var(--font-poppins);

  /* colors */
  --tenant-black: #000000;
  --tenant-blue-primary: #0066CC;
  --tenant-blue-secondary: #2684FF;
  --tenant-blue-tertiary: #2C4692;
  --tenant-blue-dark: #1C2442;
  --tenant-gray-deep: #2F3747;
  --tenant-gray-dark: #343434;
  --tenant-navy-blue: #001246;
  --tenant-blue-darker: #35447d;
  --tenant-gray-steel: #666666;
  --tenant-gray-medium: #87888C;
  --tenant-gray-nobel: #A1A1A1;
  --tenant-gray-light: #B4B4B4;
  --tenant-gray-silver: #A7A7A7;
  --tenant-gray-lighter: #DCDCDC;
  --tenant-gray-soft: #E6E6E6;
  --tenant-blue-light: #DEEBFF;
  --tenant-gray-lightest: #F4F4F4;
  --tenant-gray-lightest-alt: #FAFAFA;
  --tenant-grey-midnight-blue: #222834;
  --tenant-white: #FDFDFD;
  --tenant-white-primary: #FFFFFF;
  --tenant-red-primary: #E51921;
  --tenant-green-primary: #45BE76;
  --tenant-green-banner: #19F2A4;
  --tenant-gray-dim: #BBBBBB;
  --tenant-hover-gray: #F6F6F6;

  --tenant-radio-group-checked: #ECF3FF;
  --tenant-pre-filled-license-tag: #2C4692;
  --tenant-metadata-table: #E1E1E1;
  --tenant-settings-hover: #f3f4f6;
  --tenant-settings-selected: #ECF3FF;
  --tenant-filter-selected: #2C4692;
  --tenant-filter-border: #2C4692;
  --tenant-sortby-selected: #ECF3FF;
  --tenant-sortby-selected-text: #4585FF;
  --tenant-filter-text: #FFFFFF;
  --tenant-footer: #001246;
  --tenant-footer-social-icon: #FFFFFF;
  --tenant-primary-default: #001246;
  --tenant-primary-light: #2C4692;
  --tenant-dodger-blue: #4585FF;
  --tenant-dark-gray: #E1E1E1;

  --tenant-pagination: #001246;
  --tenant-pagination-hover: #2C4692;
  /* spacing */
  --tenant-filter-tile-padding: 10px 16px;
  --tenant-header-padding: 10px 0px 10px 16px;
  --tenant-header-padding-md: 20px 40px;

  /* border radius */
  --tenant-filter-tile-radius: 8px;

  /* shadow */
  --tenant-artwork-action-button-shadow: 0 0 4px 0 rgb(0 0 0 / 0.25);

  .tenant-filter-tile {
    padding: var(--tenant-filter-tile-padding);
    border-radius: var(--tenant-filter-tile-radius);
  }

  .tenant-header {
    padding: var(--tenant-header-padding);
  }

  .tenant-artwork-action-button {
    box-shadow: var(--tenant-artwork-action-button-shadow);
  }

  @media (min-width: 768px) {
    .tenant-header {
      padding: var(--tenant-header-padding-md);
    }
  }
}

html {
  font-family: var(--font-family-default);
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: #fff;
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

  .theme-font {
    font-family: var(--font-family-default);
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-international-phone-input--disabled,
.react-international-phone-country-selector-button--disabled {
  opacity: 0.4;
  background-color: var(--tenant-gray-lightest) !important
}

.btn-blue {
  font-weight: 600;
  font-size: 16px;
  line-height: 18.75px;
  align-items: center;
  border-width: 1px;
}

.btn-blue-rounded-4 {
  border-radius: 4px;
}

.btn-blue-rounded-8 {
  border-radius: 8px;
}
.btn-blue-primary {
  border-color: var(--tenant-pagination);
  background-color: var(--tenant-pagination);
  color: var(--tenant-white-primary);
}


.btn-blue-primary:hover {
  border-color: var(--tenant-pagination-hover);
  background-color: var(--tenant-pagination-hover);
  color: var(--tenant-white-primary);
}

.btn-blue-secondary {
  border-color: var(--tenant-pagination);
  background-color: var(--tenant-white-primary);
  color: var(--tenant-pagination);
}

.btn-blue-secondary:hover {
  border-color: var(--tenant-pagination-hover);
  background-color: var(--tenant-white-primary);
  color: var(--tenant-pagination-hover);
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  text-align: center;
}

.react-datepicker-wrapper .react-datepicker__input-container input::placeholder {
  color: var(--tenant-gray-dim);
}

.react-datepicker-wrapper .react-datepicker__input-container svg.react-datepicker__calendar-icon {
  left: 60px;
}

@media screen and (min-width: 640px) {
  .react-datepicker-wrapper .react-datepicker__input-container svg.react-datepicker__calendar-icon {
    left: unset;
  }
}

@media screen and (min-width: 1280px) {
  .react-datepicker-wrapper .react-datepicker__input-container svg.react-datepicker__calendar-icon {
    left: 40px;
  }
}

/*  */
.tenant-h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/*  */
.tenant-title-1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/*  */
.tenant-title-2 {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/*  */
.tenant-title-3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tenant-body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tenant-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tenant-breadcrumb-text {
  font-size: 12px;
  line-height: 14.4px;
}

.tenant-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: var(--tenant-blue-primary);
}

.tenant-prefilled-license-tag {
  padding: 8px;
  gap: 4px;
  border-radius: 8px;
  background-color: var(--tenant-blue-tertiary);
  color: white;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 24px;
}

.tenant-vault-checkout-total {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tenant-horizontal-tab-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.tenant-table-column-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/* Responsive styles */
@media screen and (min-width: 768px) {
  /*  */
  .tenant-h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  /*  */
  .tenant-title-1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  /*  */
  .tenant-title-2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  /*  */
  .tenant-title-3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .tenant-body-1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .tenant-body-2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .tenant-breadcrumb-text {
    font-size: 16px;
    line-height: 24px;
  }

  .tenant-link {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--tenant-blue-primary);
  }

    .tenant-prefilled-license-tag {
    padding: 8px;
    gap: 4px;
    border-radius: 8px;
    background-color: var(--tenant-blue-tertiary);
    color: white;
    display: flex;
    align-items: center;
    justify-items: center;
    height: 24px;
  }

  .tenant-vault-checkout-total {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .tenant-horizontal-tab-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .tenant-table-column-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
