html {
    .tenant-heritagemalta {
        --colors-heritagemalta-red: #F3373B;

        /* colors */
        --tenant-footer: var(--colors-heritagemalta-red);
        --tenant-dark-gray: #282C30;

        /* typography */
        .tenant-title-1 {
            font-size: 14px;
            line-height: 21px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }

        .tenant-title-2 {
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }

        .tenant-body-1 {
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
        /* Responsive */
        @media screen and (min-width: 768px) {
            /* typography */
            .tenant-title-1 {
                font-size: 16px;
                line-height: 24px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }

            .tenant-title-2 {
                font-size: 14px;
                line-height: 21px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }

            .tenant-body-1 {
                font-size: 14px;
                line-height: 21px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
            }
        }
    }
}
