.custom-tab-list {
  position: relative;
}

.custom-tab {
  position: relative;
  transition: color 0.3s ease;
}

.custom-tab[aria-selected="true"
] {
  color: #000; /* Change to your active tab color */
}

.tab-indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background: #000; /* Change to your indicator color */
  transition: transform 0.3s ease, width 0.3s ease;
}
