/* Date range picker */
.react-datepicker__month-select,
.react-datepicker__year-select {
    margin-top: 10px;
    padding: 4px 8px 4px 8px;
    border-color: #BBBBBB;
    border-radius: 8px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    display: flex !important;
    align-items: center !important;
}

.react-datepicker__input-container input {
    width: 100%;
    border-color: #BBBBBB;
    border-radius: 8px;
    padding: 6px 10px 5px 28px !important;
}